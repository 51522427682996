import React from 'react';

export default function Vision() {
  return (
    <div class="blog">
      <div class="container">
        <div class="section-header text-center"></div>
        <div class="row">
          <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.2s">
            <div class="blog-item">
              <div class="blog-title">
                <h3>VISION</h3>
              </div>
              <div class="blog-meta">
                <p>
                  By<a href="">Admin</a>
                </p>
              </div>
              <div class="blog-text">
                <p>
                  A regional market leader in provision of civil engineering and
                  building construction services
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 wow fadeInUp">
            <div class="blog-item">
              <div class="blog-title">
                <h3>MISSION</h3>
              </div>
              <div class="blog-meta">
                <p>
                  By<a href="">Admin</a>
                </p>
              </div>
              <div class="blog-text">
                <p>
                  We aim to be a world class engineering Construction Company
                  providing efficient affordable, sustainable, cost-effective
                  services of highest level of quality
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 wow fadeInUp">
            <div class="blog-item">
              <div class="blog-title">
                <h3>VALUES</h3>
              </div>
              <div class="blog-meta">
                <p>
                  By<a href="">Admin</a>
                </p>
              </div>
              <div class="blog-text">
                <p>
                  We believe in maintaining the highest standard of
                  professionalism, integrity, creativity, positive attitude,
                  delivery that meets and surpass expectations while offering
                  prompt and lasting solutions that stand the test of time.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
