import React from 'react';
import AboutUs from '../AboutUs/AboutUs';
import ContactUs from '../ContactUs/ContactUs';
import Services from '../Services/Services';
import Vision from '../Vision/Vision';
import NavBar from './NavBar';
import Slide from './Slide';
import TopBar from './TopBar';

export default function Home() {
  return (
    <div class="wrapper">
      <TopBar />
      <NavBar />
      <Slide />
      <div class="feature wow fadeInUp" data-wow-delay="0.1s">
        <div class="container-fluid">
          <div class="row align-items-center">
            <div class="col-lg-4 col-md-12">
              <div class="feature-item">
                <div class="feature-icon">
                  <i class="flaticon-worker"></i>
                </div>
                <div class="feature-text">
                  <h3>Expert Worker</h3>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-12">
              <div class="feature-item">
                <div class="feature-icon">
                  <i class="flaticon-building"></i>
                </div>
                <div class="feature-text">
                  <h3>Quality Work</h3>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-12">
              <div class="feature-item">
                <div class="feature-icon">
                  <i class="flaticon-call"></i>
                </div>
                <div class="feature-text">
                  <h3>24/7 Support</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AboutUs />
      <Vision />
      <Services />
      <ContactUs />
      <a href="#" class="back-to-top">
        <i class="fa fa-chevron-up"></i>
      </a>
    </div>
  );
}
