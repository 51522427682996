import React from 'react';
import logo from './images/logo.png';

export default function TopBar() {
  return (
    <div class="top-bar">
      <div class="container-fluid">
        <div class="row align-items-center">
          <div class="col-lg-4 col-md-12">
            <div class="logo">
              <a href="#">
                <img src={logo} alt="Logo" />
              </a>
            </div>
          </div>
          <div class="col-lg-8 col-md-7 d-none d-lg-block">
            <div class="row">
              <div class="col-4">
                <div class="top-bar-item">
                  <div class="top-bar-icon">
                    <i class="flaticon-calendar"></i>
                  </div>
                  <div class="top-bar-text">
                    <h3>Opening Hour</h3>
                    <p>Mon - Fri, 8:00 - 9:00</p>
                  </div>
                </div>
              </div>
              <div class="col-4">
                <div class="top-bar-item">
                  <div class="top-bar-icon">
                    <i class="flaticon-call"></i>
                  </div>
                  <div class="top-bar-text">
                    <h3>Call Us</h3>
                    <p>: +918848810561 </p>
                  </div>
                </div>
              </div>
              <div class="col-4">
                <div class="top-bar-item">
                  <div class="top-bar-icon">
                    <i class="flaticon-send-mail"></i>
                  </div>
                  <div class="top-bar-text">
                    <h3>Email Us</h3>
                    <p>projects@calinfra.in</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
