import React from 'react';

export default function NavBar() {
  return (
    <div class="nav-bar">
      <div class="container-fluid">
        <nav class="navbar navbar-expand-lg bg-dark navbar-dark">
          <a href="#" class="navbar-brand">
            MENU
          </a>
          <button
            type="button"
            class="navbar-toggler"
            data-toggle="collapse"
            data-target="#navbarCollapse"
          >
            <span class="navbar-toggler-icon"></span>
          </button>

          <div
            class="collapse navbar-collapse justify-content-between"
            id="navbarCollapse"
          >
            <div class="navbar-nav mr-auto">
              <a href="#" class="nav-item nav-link active">
                Home
              </a>
              <a href="#" class="nav-item nav-link">
                About
              </a>
              <a href="#" class="nav-item nav-link">
                Vision
              </a>
              <a href="#" class="nav-item nav-link">
                Service
              </a>

              <a href="#" class="nav-item nav-link">
                Contact US
              </a>
            </div>
            <div class="ml-auto">
              <a
                class="btn"
                href="https://api.whatsapp.com/send?phone=918848810561"
                target="_blank"
              >
                Get A Quote
              </a>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
}
