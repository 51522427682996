import React from 'react';
import about from './about.jpg';

export default function AboutUs() {
  return (
    <>
      <div class="about wow fadeInUp" data-wow-delay="0.1s">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-5 col-md-6">
              <div class="about-img">
                <img src={about} alt="Image" />
              </div>
            </div>
            <div class="col-lg-7 col-md-6">
              <div class="section-header text-left">
                <p>Welcome to Calinfra</p>
              </div>
              <div class="about-text">
                <p>
                  We work closely with our clients to accurately interpret their
                  dreams/visions in drawings and bring them to the desired
                  reality through construction solutions to meet their needs; We
                  communicate with our clients throughout the construction
                  process to keep them informed of progress and to ensure that
                  our project keeps on schedule and within budget.
                </p>
                <p>
                  To infrastructure development companies, , telecom providers,
                  public work agencies, municipalities and commercial /
                  residential real estate developers. Our area of expertise
                  including but not limited to infrastructure related civil
                  structural works.
                </p>
                <a class="btn" href="">
                  Learn More
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="fact">
        <div class="container-fluid">
          <div class="row counters">
            <div class="col-md-6 fact-left wow slideInLeft">
              <div class="row">
                <div class="col-6">
                  <div class="fact-icon">
                    <i class="flaticon-worker"></i>
                  </div>
                  <div class="fact-text">
                    <h2 data-toggle="counter-up">10+</h2>
                    <p>Expert Workers</p>
                  </div>
                </div>
                <div class="col-6">
                  <div class="fact-icon">
                    <i class="flaticon-building"></i>
                  </div>
                  <div class="fact-text">
                    <h2 data-toggle="counter-up">100+</h2>
                    <p>Happy Clients</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 fact-right wow slideInRight">
              <div class="row">
                <div class="col-6">
                  <div class="fact-icon">
                    <i class="flaticon-address"></i>
                  </div>
                  <div class="fact-text">
                    <h2 data-toggle="counter-up">100+</h2>
                    <p>Completed Projects</p>
                  </div>
                </div>
                <div class="col-6">
                  <div class="fact-icon">
                    <i class="flaticon-crane"></i>
                  </div>
                  <div class="fact-text">
                    <h2 data-toggle="counter-up">15+</h2>
                    <p>Running Projects</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
