import React from 'react';

import service1 from './images/service-1.jpg';
import service2 from './images/service-2.jpg';
import service3 from './images/service-3.jpg';
import service4 from './images/service-4.jpg';
import service5 from './images/service-5.jpg';
import service6 from './images/service-6.jpg';

export default function Services() {
  return (
    <div class="service">
      <div class="container">
        <div class="section-header text-center">
          <p>Our Services</p>
          <h2>We Provide Services</h2>
        </div>
        <div class="row">
          <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
            <div class="service-item">
              <div class="service-img">
                <img src={service1} alt="Image" />
                <div class="service-overlay">
                  <p>
                    Building construction is the process of adding structure to
                    real property. The vast majority of building construction
                    projects are small renovations, such as the addition of a
                    room, or renovation of a bathroom. Often, the owner of the
                    property acts as a laborer, paymaster, and design team for
                    the entire project
                  </p>
                </div>
              </div>
              <div class="service-text">
                <h3>Building Construction</h3>
                <a class="btn" href="img/service-1.jpg" data-lightbox="service">
                  +
                </a>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.2s">
            <div class="service-item">
              <div class="service-img">
                <img src={service2} alt="Image" />
                <div class="service-overlay">
                  <p>
                    Home renovation (also called home remodeling) is the process
                    of improving a broken, damaged, or outdated structure
                    residential structure to make it more aesthetically pleasing
                    or more functional – or both
                  </p>
                </div>
              </div>
              <div class="service-text">
                <h3>House Renovation</h3>
                <a class="btn" href="img/service-2.jpg" data-lightbox="service">
                  +
                </a>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
            <div class="service-item">
              <div class="service-img">
                <img src={service3} alt="Image" />
                <div class="service-overlay">
                  <p>
                    Architectural design is a discipline that focuses on
                    covering and meeting the needs and demands, to create living
                    spaces, using certain tools and especially, creativity.
                    Therefore, the aim is to combine the technological and the
                    aesthetic, despite the general belief that architecture is
                    only a technological task.
                  </p>
                </div>
              </div>
              <div class="service-text">
                <h3>Architecture Design</h3>
                <a class="btn" href="img/service-3.jpg" data-lightbox="service">
                  +
                </a>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.4s">
            <div class="service-item">
              <div class="service-img">
                <img src={service4} alt="Image" />
                <div class="service-overlay">
                  <p>
                    An Interior Designer is a professional who is responsible
                    for making the indoors functional, safe, and beautiful. They
                    determine space requirements and select essential items like
                    colors or lighting to create an ambiance that is welcoming
                    in any setting.
                  </p>
                </div>
              </div>
              <div class="service-text">
                <h3>Interior Design</h3>
                <a class="btn" href="img/service-4.jpg" data-lightbox="service">
                  +
                </a>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
            <div class="service-item">
              <div class="service-img">
                <img src={service5} alt="Image" />
                <div class="service-overlay">
                  <p>
                    Tech support workers manage, maintain, and repair IT
                    systems. Their responsibilities include diagnosing and
                    repairing faults, resolving network issues, and installing
                    and configuring hardware and software. Completely free
                    trial, no card required.
                  </p>
                </div>
              </div>
              <div class="service-text">
                <h3>Fixing & Support</h3>
                <a class="btn" href="img/service-5.jpg" data-lightbox="service">
                  +
                </a>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.6s">
            <div class="service-item">
              <div class="service-img">
                <img src={service6} alt="Image" />
                <div class="service-overlay">
                  <p>
                    painting, the expression of ideas and emotions, with the
                    creation of certain aesthetic qualities, in a
                    two-dimensional visual language. The elements of this
                    language—its shapes, lines, colours, tones, and textures—are
                    used in various ways to produce sensations of volume, space,
                    movement, and light on a flat surface
                  </p>
                </div>
              </div>
              <div class="service-text">
                <h3>Painting</h3>
                <a class="btn" href="img/service-6.jpg" data-lightbox="service">
                  +
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
